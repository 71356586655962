<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h2 class="my-2 font-semibold text-blue-400 text-2xl">
        Terminals Requests
      </h2>

      <div class="grid grid-cols-5 gap-5 mt-6">
        <div
          class="border shadow-md text-center p-2 rounded-md"
          v-for="type in types"
          :key="type.status"
        >
          <p class="underline">{{ type.status }}</p>
          <p>{{ filterRequestTerminals(type.status).length }}</p>
        </div>
      </div>
      <div class=" text-right flex justify-end  items-end">
        <button
          @click="downloadCsv"
          class="
                    block
                    uppercase
                    shadow
                    bg-green-800
                    hover:bg-blue-700
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    py-2
                    px-8
                    rounded
                  "
        >
          Download CSv
        </button>
      </div>

      <div class="grid grid-cols-7 mt-10 mb-4">
        <div class="border-indigo-600" v-for="type in types" :key="type.number">
          <div
            @click="current = type.status"
            :class="{ 'bg-gray-500 text-white': type.status === current }"
            class="border border-2 cursor-pointer py-1 text-center"
          >
            {{ type.status.replace(/_/g, " ") }}
          </div>
        </div>
      </div>

      <div class="w-full flex justify-end items-end mb-5">
        <select
          v-model="terminalSort"
          type="text"
          required
          class="
            shadow
            mr-4
            appearance-none
            border border-2
            text-xs
            rounded
            py-2
            leading-5
            px-3
            w-64
            text-gray-700
            mt-1
            focus:outline-none
            focus:shadow-outline
          "
        >
          <option value="ALL">ALL Terminals</option>
          <option value="MPOS">MPOS</option>
          <option value="LINUX">LINUX</option>
          <option value="ANDROID">ANDROID</option>
        </select>
        <div class="w-64 mr-3">
          <multiselect
            :taggable="false"
            v-model="currentState"
            :options="states"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Select State"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single hidden"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>

        <select
          v-model="dateSort"
          type="text"
          required
          class="
            shadow
            appearance-none
            border border-2
            text-xs
            rounded
            py-2
            leading-5
            px-3
            w-64
            text-gray-700
            mt-1
            focus:outline-none
            focus:shadow-outline
          "
        >
          <option value="ascending">Sort by Date (Ascending)</option>
          <option value="descending">Sort by Date (Descending)</option>
        </select>

        <select
          v-model="sourceSort"
          type="text"
          required
          class="
            shadow
            appearance-none
            border border-2
            text-xs
            rounded
            py-2
            leading-5
            px-3
            w-64
            text-gray-700
            mt-1
            focus:outline-none
            focus:shadow-outline
          "
        >
          <option value="ALL">All Sources</option>
          <option value="WEB">Sort by Main Web</option>
          <option value="TRACTIONWEB">Sort by Traction Web</option>
          <option value="MOBILEAPP">Sort by MOBILEAPP</option>
          <option value="SALESFORCE">Sort by SALESFORCE</option>
        </select>
      </div>

      <div class="overflow-scroll pb-6" style="height: 600px;overflow:auto">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="16"
                style="background: #DEE6F2;"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Merchant Id
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Business Name
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Quantity
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Payment Method
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Source
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Delivery Address
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Delivery City
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Delivery State
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Delivery Phone number
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Date Requested
              </th>

              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Terminal Type
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Accessories
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Date of preferred delivery
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Referral Code
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                LGA
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              >
                Aggregator
              </th>
              <th
                class="
                  bg-blue-100
                  text-sm
                  border
                  whitespace-nowrap
                  text-left
                  px-8
                  py-2
                "
              ></th>
            </tr>
          </thead>
          <tbody v-if="filterRequestTerminals(current, currentNumber).length">
            <tr
              v-for="(terminals, i) in filterRequestTerminals(
                current,
                currentNumber
              )"
              :key="i"
              class="hover:bg-gray-300"
            >
              <!-- <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.merchant_name }}
              </td> -->
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.businessId }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.businessName || "N/A" | truncate(30, "...") }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{ terminals.quantity }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{
                  terminals.terminalPrice.leaseToOwn !== "0"
                    ? "Lease To Own"
                    : terminals.terminalPrice.outrightPurchase !== "0"
                    ? "Outright Purchase"
                    : "Subscription"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.source || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryAddress || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryCity || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryState || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryPhoneNumber || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(terminals.dateCreated).format(
                    "DD MMM YYYY - h:mm:ss a"
                  ) || "N/A"
                }}
              </td>

              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.terminalPrice.terminalType || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <div
                  v-for="(item, index) in terminals.accessoryRequest"
                  :key="index"
                >
                  <div class="flex" v-if="item.quantity">
                    <div>Name:{{ item.accessoriesPrice.accessoriesType }},</div>
                    <br />
                    <div class="pl-1">Quantity:{{ item.quantity }}</div>
                  </div>
                </div>
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(terminals.deliveryDate, "DD-MM-YYYY h-mm-ss").format(
                    "DD MMM YYYY - h:mm:ss a"
                  ) || "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.salesRefCode || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliverylga || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.business.parent_aggregator_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="sendVal(terminals, i)"
                  class="
                    block
                    uppercase
                    shadow
                    bg-green-800
                    hover:bg-blue-700
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    py-2
                    px-8
                    rounded
                  "
                >
                  Change Status
                </button>
              </td>
            </tr>
          </tbody>
          <div class="bg-white shadow-md absolute w-full py-3 pl-5" v-else>
            There is no data available
          </div>
        </table>
      </div>
    </div>
    <div class="mt-3">
      <button
        @click="prevPage(currentPage)"
        :disabled="currentPage === 1 ? isDisabled : !isDisabled"
        class="
                    
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    text-white
                    focus:shadow-outline
                    focus:outline-none
                   text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
        :class="currentPage === 1 ? 'bg-blue-200' : 'bg-blue-900  '"
        :data-currPage="currentPage"
      >
        &laquo; Prev
      </button>
      <button
        @click="nextPage(currentPage)"
        class="
                    float-right
                    uppercase
                    shadow
                    ml-3
                    hover:bg-blue-600
                    bg-blue-900
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-3
                    px-8
                    rounded
                  "
      >
        Next &raquo;
      </button>
    </div>
    <Modal size="max-w-5xl" v-if="modal" @close="modal = false">
      <div class="flex items-center justify-between">
        <svg
          @click="modalIndex -= 1"
          :class="{
            ' cursor-not-allowed  pointer-events-none text-gray-300 fill-current':
              modalIndex == 0,
          }"
          class="w-10 h-10 cursor-pointer fill-current"
          viewBox="0 0 492 492"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12    C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084    c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864    l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
              />
            </g>
          </g>
        </svg>

        <div class="w-full px-4">
          <div class="w-full pl-10">
            <p class="text-center underline pb-10">
              {{ current }}
            </p>
            <div class="w-full flex items-center justify-center">
              <p class="text-center border-2 inline-block rounded-full p-4">
                {{ modalIndex + 1 }}
              </p>
            </div>
            <!-- <div class="flex items-center py-2 ">
              Merchant Name:
              <span class="ml-2 text-gray-900 font-bold">
                {{
                  filterRequestTerminals(current, currentNumber)[modalIndex]
                    .merchantName
                }}</span
              >
            </div> -->
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Merchant Id:
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <span class="text-gray-900 font-bold">
                  {{
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .businessId
                  }}
                </span>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Business Name:
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <span class="text-gray-900 font-bold">
                  {{
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .business.name
                  }}
                </span>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Quantity:
              </div>

              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <input
                  type="number"
                  class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                  v-model="
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .quantity
                  "
                  min="1"
                />
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Type:
              </div>

              <div class="w-full  xl:my-4 xl:px-4 xl:w-1/3">
                <select
                  class="inline-block p-2 ring-1 outline-none ring-gray-300 rounded-sm w-40 text-sm"
                  name=""
                  id=""
                  v-model="selectedTerminalType"
                  required
                >
                  <option value="">----</option>
                  <option
                    :value="terminalType.terminalType"
                    v-for="(terminalType, i) in terminalTypeList"
                    :key="i"
                    >{{ terminalType.terminalType }}</option
                  >
                </select>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Payment Method:
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <select
                  class="inline-block p-2 ring-1 outline-none ring-gray-300 rounded-sm w-40 text-sm"
                  name=""
                  id=""
                  v-model="selectedPaymentMethod"
                  required
                >
                  <option value="">----</option>
                  <option
                    :value="payment"
                    v-for="(payment, i) in paymentMethodList"
                    :key="i"
                    >{{ payment.name }}</option
                  >
                </select>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Date Requested:
              </div>

              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <input
                  disabled
                  type="type"
                  class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                  v-model="
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .deliveryDate
                  "
                />
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Delivery Address:
              </div>

              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <input
                  type="text"
                  class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                  v-model="
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .deliveryAddress
                  "
                />
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Delivery City:
              </div>

              <div class="w-full  xl:my-4 xl:px-4 xl:w-1/3">
                <input
                  type="text"
                  class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                  v-model="
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .deliveryCity
                  "
                />
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Delivery State:
              </div>

              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <input
                  type="text"
                  class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                  v-model="
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .deliveryState
                  "
                />
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Delivery Phonenumber:
              </div>

              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <input
                  type="text"
                  class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                  v-model="
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .deliveryPhoneNumber
                  "
                />
              </div>
            </div>

            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Accessories:
              </div>

              <div class="w-full  xl:my-4 xl:px-4 xl:w-1/3">
                <div
                  v-for="(item, index) in filterRequestTerminals(
                    current,
                    currentNumber
                  )[modalIndex].accessoryRequest"
                  :key="index"
                >
                  <div
                    class="flex text-blue-500"
                    v-if="terminalTypeList.accessories.length > 0"
                  >
                    <div>
                      Name:
                      <input
                        type="text"
                        class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                        v-model="item.accessoriesPrice.accessoriesType"
                      />
                    </div>
                    <div class="mx-2">,</div>

                    <div class="pl-1">
                      Quantity:
                      <input
                        type="number"
                        class="p-2 ring-1 w-40 outline-none ring-gray-300 rounded-sm text-sm"
                        v-model="item.quantity"
                        min="1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Change Status:
              </div>

              <div class="w-full  xl:my-4 xl:px-4 xl:w-1/3">
                <select
                  v-model="updateStatus"
                  class="border-2 px-2 py-2"
                  name=""
                  id=""
                >
                  <option :value="null" disabled>Select Status</option>
                  <option
                    v-for="type in types.filter((x) => x.status !== current)"
                    :key="type.number"
                    :value="type.status"
                  >
                    {{ type.status }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                Comment
              </div>

              <div class="w-full  xl:my-4 xl:px-4 xl:w-1/3">
                <textarea
                  class="border-2 border-gray-400 rounded-md w-80 p-2"
                  name=""
                  id=""
                  v-model="
                    filterRequestTerminals(current, currentNumber)[modalIndex]
                      .comment
                  "
                  rows="5"
                ></textarea>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div
                class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3"
              ></div>

              <div class="w-full  xl:my-4 xl:px-4 xl:w-1/3">
                <button
                  @click="updateTermimalRequest"
                  class="block uppercase shadow mt-5 bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                >
                  Update Request
                </button>
              </div>
            </div>
          </div>
        </div>
        <svg
          @click="modalIndex += 1"
          :class="{
            ' cursor-not-allowed  pointer-events-none text-gray-300 fill-current':
              modalIndex ===
              filterRequestTerminals(current, currentNumber).length - 1,
          }"
          class="w-10 h-10 cursor-pointer"
          viewBox="0 0 492.004 492.004"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
              />
            </g>
          </g>
        </svg>
      </div>
    </Modal>
  </main>
</template>

<script>
import Modal from "../components/Modal.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import { json2excel } from "js2excel";

export default {
  data() {
    return {
      moment,
      paymentMethodList: [],
      terminalTypeList: [],
      selectedPaymentMethod: "",
      selectedTerminalType: "",
      paymentMethod: "",
      states: [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
      ],
      currentState: [],
      modal: false,
      comment: "",
      isLoading: false,
      modalIndex: null,
      terminalSort: "ALL",
      sourceSort: "ALL",
      search: "",
      requestTerminals: [],
      current: "NEW",
      currentNumber: "0",
      updateStatus: null,
      dateSort: "ascending",
      types: [
        {
          status: "NEW",
        },
        {
          status: "NOT_INTERESTED",
        },
        {
          status: "INTERESTED",
        },
        {
          status: "NOT_APPROVED",
        },
        {
          status: "APPROVED",
        },
        {
          status: "DISPATCHED",
        },
        {
          status: "DEPLOYED",
        },
      ],
      currentPage: 1,
      isDisabled: true,
    };
  },
  watch: {
    // async updateStatus(x) {
    //   if (x) {
    //     await this.update();
    //   }
    // },
    selectedTerminalType() {
      this.paymentMethodList = this.selectedTerminalType.pricing;
    },
  },
  components: {
    Modal,
    Multiselect,
  },
  computed: {
    sortbyDateFilter() {},
  },
  methods: {
    terminalPricingComputed(current, currentNumber, modalIndex) {
      this.selectedPaymentMethod =
        this.filterRequestTerminals(current, currentNumber)[modalIndex]
          .terminalPrice.leaseToOwn !== "0"
          ? "Lease To Own"
          : this.filterRequestTerminals(current, currentNumber)[modalIndex]
              .terminalPrice.outrightPurchase !== "0"
          ? "Outright Purchase"
          : "Subscription";
    },
    terminalTypeComputed(current, currentNumber, modalIndex) {
      this.selectedTerminalType = this.filterRequestTerminals(
        current,
        currentNumber
      )[modalIndex].terminalPrice.terminalType;
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_REQUESTS_TERMINAL",
        {
          page: currPage - 1,
        }
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_REQUESTS_TERMINAL",
        {
          page: currPage + 1,
        }
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
    filterRequestTerminals(x) {
      let y = [...this.requestTerminals].filter((b) => {
        return b.status === x;
      });

      let k = this.dateSort === "ascending" ? y.sort() : y.reverse();
      if (this.terminalSort !== "ALL") {
        k = [...k].filter((x) => {
          return (
            x.terminalPrice.terminalType.toLowerCase() ==
            this.terminalSort.toLowerCase()
          );
        });
      }

      if (this.sourceSort !== "ALL") {
        k = [...k].filter((x) => {
          return x.source.toLowerCase() == this.sourceSort.toLowerCase();
        });
      }

      return k && this.currentState.length === 0
        ? k
        : k.filter((x) => {
            return this.currentState
              .map((x) => x.toLowerCase())
              .includes(x.deliveryState.toLowerCase());
          });
    },
    downloadCsv() {
      let data = this.requestTerminals.map((data) => {
        return {
          "Business Id": data.businessId,
          "Business Name": data.business.name,
          "Delivery Address": data.deliveryAddress,
          "Delivery City": data.deliveryCity,
          "Delivery Phone Number": data.deliveryPhoneNumber,
          "Delivery State": data.deliveryState,
          "Quantity ": data.quantity,
          "Terminal Type": data.terminalPrice.terminalType,
          "Terminal Accessories": data.terminalPrice.accessories,
          status: data.status,
          "Payment Method":
            data.terminalPrice.leaseToOwn !== "0"
              ? "Lease To Own"
              : data.terminalPrice.outrightPurchase !== "0"
              ? "Outright Purchase"
              : "Subscription",
          Source: data.source,

          "Date Requested":
            moment(data.dateCreated).format("DD MMM YYYY - h:mm:ss a") || "N/A",
          "Date of Preferred delivery":
            moment(data.deliveryDate).format("DD MMM YYYY - h:mm:ss a") ||
            "N/A",
          "Referral Code": data.salesRefCode,
          LGA: data.deliverylga,
        };
      });
      json2excel({
        data,
        name: "Requested Terminals",
        formateDate: this.moment().format("DD MMM YYYY - h:mm:ss a"),
      });
    },
    sendVal(x, y) {
      this.modal = true;
      this.modalIndex = y;
      this.terminalPricingComputed(this.current, this.currentNumber, y);
      this.terminalTypeComputed(this.current, this.currentNumber, y);

      if (this.selectedTerminalType) {
        const selectedTerminalList = this.terminalTypeList.filter(
          (el) => el.terminalType === this.selectedTerminalType
        );
        this.paymentMethodList = selectedTerminalList[0].pricing;
        console.log(this.selectedTerminalType);
      }
    },
    async updateTermimalRequest() {
      let comment = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].comment;
      let status = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].status;
      let requestId = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].requestId;
      let terminalType = this.selectedTerminalType;
      let paymentMethod = this.selectedPaymentMethod.name;
      let deliveryAddress = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].deliveryAddress;
      let deliveryCity = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].deliveryCity;
      let deliveryState = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].deliveryState;
      let deliveryPhoneNumber = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].deliveryPhoneNumber;

      let quantity = this.filterRequestTerminals(
        this.current,
        this.currentNumber
      )[this.modalIndex].quantity;

      let payload = {
        terminalType,
        paymentMethod,
        deliveryAddress,
        deliveryState,
        quantity,
        deliveryCity,
        deliveryPhoneNumber,
        comment,
        status,
        requestId,
      };
      this.isLoading = true;
      let res = await this.$store.dispatch(
        "NEW_UPDATE_TERMINAL_REQUEST",
        payload
      );
      let new_res = await this.$store.dispatch("UPDATE_TERMINAL_REQUEST", {
        comment,
        status,
        requestId,
      });
      if (res.status && new_res.status) {
        let res = await this.$store.dispatch("FETCH_ALL_TERMINAL_REQUEST");
        this.requestTerminals = res.data;
      }
      this.isLoading = false;
    },
    async update(x) {
      if (this.updateStatus === null) {
        alert("Select new status from dropdown");
        return;
      }
      let b = this.filterRequestTerminals(this.current, this.currentNumber)[
        this.modalIndex
      ];

      let payload = {
        status: this.updateStatus,
        requestId: b.requestId,
        comment: this.comment,
      };
      if (
        this.filterRequestTerminals(this.current, this.currentNumber).length ===
        1
      ) {
        this.modal = false;
      }
      let res = await this.$store.dispatch("UPDATE_TERMINAL_REQUEST", payload);
      if (res.status) {
        if (this.modalIndex > 1) {
          this.modalIndex -= 1;
        }
        this.updateStatus = null;

        let res = await this.$store.dispatch("FETCH_ALL_TERMINAL_REQUEST");
        this.requestTerminals = res.data;
      }
    },
    async getterminatypes() {
      let res = await this.$store.dispatch("FETCH_ALL_TERMINAL_TYPES");
      this.terminalTypeList = res.data;
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.isLoading = true;
    let res = await this.$store.dispatch("FETCH_ALL_TERMINAL_REQUEST");
    if (res.status) {
      this.requestTerminals = res.data;
      // console.log(res.headers);
    } else {
      alert(res.message);
    }

    if (res) {
      this.isLoading = false;
    }
    await this.getterminatypes();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
